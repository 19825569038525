import React, { Component } from 'react';
import { Button } from '@material-ui/core';

import 'components/Shared/scss/inner.scss';
import { ReactComponent as VKTeamsLogo } from 'components/Shared/i/logo-VKTeams.svg';

import { goToDeeplink } from 'components/deeplink'
import { getIOSLink } from '../OTPForm/OTPServer';
import { withRouter } from 'react-router-dom';

class PageHome extends Component {
    componentDidMount() {
        goToDeeplink(document.location);
    }

    installButtonHandler() {
        if (!getIOSLink()) {
            this.props.history.push('/auth');
            return false;
        }
    }

    getInstallButtonHref() {
        return getIOSLink() || '/auth';
    }

    render() {
        return (
            <div className="page">
                <header className="header" id="header">
                    <a href="/" className="header__logo" id="logo" data-goto-section="0">
                        <VKTeamsLogo alt="VK Teams"/>
                    </a>
                </header>
                <div className="download-page background_ios" id="downloadLinks">
                    <div style={{width: '294px', margin: '87px auto 0 auto'}}>
                        <Button className="download-page__rtfm-button" onClick={this.installButtonHandler.bind(this)} href={this.getInstallButtonHref()}>Скачать</Button>
                    </div>

                    <div className="download-page__rtfm-title">
                        <h2>Первый запуск в iOS</h2>
                    </div>

                    <div className="download-page__rtfm-box">
                        <p style={{ fontSize: '20px', marginTop: '24px', lineHeight: '24px' }}>
                            После установки приложения, при первом запуске появится сообщение Ненадежный корпоративный разработчик – это потому,
                            что приложение установлено не из AppStore.<br />
                            Вам необходимо в телефоне зайти в
                        </p>

                        <div style={{ backgroundColor: 'rgba(0, 119, 255, 0.2)', padding: '14px 14px 14px 14px', borderRadius: '15px', margin: '14px 0px 0px 0px'}}>
                            <p style={{ fontStyle: 'normal', fontWeight: 'normal', fontSize: '20px', lineHeight: '24px' }}>
                                Системные настройки -> Основные -> VPN и управление устройством -> «'V KONTAKTE' LTD»
                                и нажать Доверять «iPhone Distribution 'V KONTAKTE' LTD».
                            </p>
                        </div>

                        <p style={{ fontSize: '20px', marginTop: '24px', lineHeight: '24px' }}>
                            После этого вы можете запустить приложение.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PageHome);
