import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOTPContext } from './OTPProvider';
import { t } from './OTPText';
import { getServerErrorMessage, goToIOSLink, OTPServer } from './OTPServer';

const otpPasswordSchema = yup.object().shape({
    password: yup.string().required(t('auth.msgWrongLoginOrPassword')),
});

const OTPPassword = () => {
    const { password, otpEmail, setPassword, setIsPassword } = useOTPContext();
    const [error, setError] = useState('');
    const history = useHistory();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(otpPasswordSchema),
        defaultValues: {
            password,
        },
    });

    useEffect(() => {
        setIsPassword(true);
        return () => {
            setIsPassword(false);
        };
    }, [setIsPassword]);

    const onSubmit = async (data) => {
        setPassword(data.password);
        setError('');
        try {
            await OTPServer.submitUsernamePassword(otpEmail, data.password);
            goToIOSLink();
            history.goBack();
        } catch ([httpCode, statusCode]) {
            const msg = getServerErrorMessage(httpCode, statusCode);
            setError(msg);
        }
    };
    return (
        <>
            <span className='imAuthTitle im-auth-title'>{t('auth.enterPassword')}</span>
            <span className='imAuthSubtitle im-auth-description'>
                {t('auth.businessOTPAuthPassword')}<span className={'im-marker'}>{otpEmail}</span>
            </span>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <input
                    {...register('password')}
                    type='password'
                    className='imAuthPassword im-text-input im-auth-input-pswd'
                    placeholder={t('auth.password')}
                    autoFocus
                />
                <span className="imAuthError im-auth-error">
                    {error || errors?.password?.message}
                </span>
                <button className='imButton im-auth-submit' data-action='submit' type={'submit'}>
                    {t('auth.login')}
                </button>
            </form>
        </>
    );
};

export { OTPPassword };
